import React from 'react';

const Dady = () => {

    return <div className="table-responsive">
        <table className="table table-striped table-condensed">
            <thead>
                <tr>
                    <th
                        className="text-center bg-success text-white"
                        colspan="4"
                        scope="col"
                    >
                        SHYAMA SUNDAR PADHY
              </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th colspan="2" scope="row">PAN</th>
                    <td colspan="2">ADZPP4647C</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">ADHAAR NO</th>
                    <td colspan="2">609780911199</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">D.O.B</th>
                    <td colspan="2">10-DECEMBER-1961</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Mobile No</th>
                    <td colspan="1">9439802792</td>
                    <td colspan="1">9937547965</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Email ID</th>
                    <td colspan="2">shyamasundarpadhy@gmail.com</td>
                </tr>
                <tr>
                    <th scope="row">SBI BHAPUR</th>
                    <td>10403495219</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0005753</td>
                </tr>
                <tr>
                    <th scope="row">INDIAN BANK KHALLIKOTE</th>
                    <td>6111613102</td>
                    <th scope="row">IFSC CODE</th>
                    <td>IDIB000K033</td>
                </tr>
                <tr>
                    <th scope="row">UGB GURUNTHI</th>
                    <td>84034613231</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0RRUKGB</td>
                </tr>
                <tr>
                    <th scope="row">GIS NO</th>
                    <td>4497NGC</td>
                    <th scope="row">GPF NO</th>
                    <td>7346CE</td>
                </tr>
                <tr>
                    <th scope="row">COUNCIL REG NO</th>
                    <td>3096</td>
                    <th scope="row">UNIVERSITY REG NO</th>
                    <td>1027-4188</td>
                </tr>
                <tr>
                    <th scope="row">HUGULAPTA PIN</th>
                    <td>761001</td>
                    <th scope="row">KHALLIKOTE PIN</th>
                    <td>761030</td>
                </tr>
            </tbody>
        </table>
    </div>

}

export default Dady;