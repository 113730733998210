import React from 'react';

import Dady from './Dady';
import Sai from './Sai';
import Mumy from './Mumy';
import Chinu from './Chinu';



const App = () => {
    return <React.Fragment>
        <Dady />
        <Sai />
        <Mumy />
        <Chinu />
    </React.Fragment>
}

export default App;