import React from 'react';

const Sai = () => {
    return <div className="table-responsive">
        <table className="table table-striped table-condensed">
            <thead>
                <tr>
                    <th
                        className="text-center bg-primary text-white"
                        colspan="4"
                        scope="col"
                    >
                        SAIKRISHNA PANDA
              </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th colspan="2" scope="row">PAN</th>
                    <td colspan="2">CFDPP1034B</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">ADHAAR NO</th>
                    <td colspan="2">6320 3889 6961</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">D.O.B</th>
                    <td colspan="2">21-FEBRUARY-1995</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Mobile No</th>
                    <td colspan="2">9178296494</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Email ID</th>
                    <td colspan="2">itssai91@gmail.com</td>
                </tr>
                <tr>
                    <th scope="row">ICICI a/c NO</th>
                    <td>149701504910</td>
                    <th scope="row">IFSC CODE</th>
                    <td>ICIC0001497</td>
                </tr>
                <tr>
                    <th scope="row">SBI a/c NO</th>
                    <td>32910224191</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0005753</td>
                </tr>
                <tr>
                    <th scope="row">IOB a/c NO</th>
                    <td>070201000011581</td>
                    <th scope="row">IFSC CODE</th>
                    <td>IOBA0000702</td>
                </tr>
                <tr>
                    <th scope="row">UGB a/c NO</th>
                    <td>13520585265</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0RRUKGB</td>
                </tr>
            </tbody>
        </table>
    </div>
}

export default Sai;