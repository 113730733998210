import React from 'react';

const Mumy = () => {
    return <div className="table-responsive">

        <table className="table table-striped table-condensed">
            <thead>
                <tr>
                    <th
                        className="text-center bg-info text-white"
                        colspan="4"
                        scope="col"
                    >
                        SUSAMA PANDA
              </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th colspan="2" scope="row">PAN</th>
                    <td colspan="2">BEDPP9797R</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">ADHAAR NO</th>
                    <td colspan="2">6065 2055 3361</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">D.O.B</th>
                    <td colspan="2">10-MARCH-1964</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Mobile No</th>
                    <td colspan="2">9937680616</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Email ID</th>
                    <td colspan="2">susama.panda234@gmail.com</td>
                </tr>
                <tr>
                    <th scope="row">SBI MAIN BRANCH</th>
                    <td>10859334986</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0000033</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">SBI MAIN BRANCH CIF</th>
                    <td colspan="2">80690497075</td>
                </tr>
                <tr>
                    <th scope="row">INDIAN BANK BAM</th>
                    <td>6934325005</td>
                    <th scope="row">IFSC CODE</th>
                    <td>IDIB000C149</td>
                </tr>
                <tr>
                    <th scope="row">UGB GURUNTHI</th>
                    <td>84034613231</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0RRUKGB</td>
                </tr>
                <tr>
                    <th scope="row">UGB a/c NO</th>
                    <td></td>
                    <th scope="row">IFSC CODE</th>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>
}

export default Mumy;