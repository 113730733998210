import React from 'react';

const Chinu = () => {

    return <div className="table-responsive">

        <table className="table table-striped table-condensed">
            <thead>
                <tr>
                    <th
                        className="text-center bg-danger text-white"
                        colspan="4"
                        scope="col"
                    >
                        SUMAN PANDA
              </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th colspan="2" scope="row">PAN</th>
                    <td colspan="2">DETPP9550J</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">ADHAAR NO</th>
                    <td colspan="2">4021 3042 0678</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">D.O.B</th>
                    <td colspan="2">28-MARCH-2001</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Mobile No</th>
                    <td colspan="2">8763345988</td>
                </tr>
                <tr>
                    <th colspan="2" scope="row">Email ID</th>
                    <td colspan="2">suman45225@gmail.com</td>
                </tr>
                <tr>
                    <th scope="row">SBI BHAPUR BRANCH</th>
                    <td>20435528710</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0005733</td>
                </tr>
                <tr>
                    <th scope="row">INDIAN BANK BAM</th>
                    <td>6905418950</td>
                    <th scope="row">IFSC CODE</th>
                    <td>IDIB000C149</td>
                </tr>
                <tr>
                    <th scope="row">UGB GURUNTHI</th>
                    <td>84034613231</td>
                    <th scope="row">IFSC CODE</th>
                    <td>SBIN0RRUKGB</td>
                </tr>
            </tbody>
        </table>
    </div>

}

export default Chinu;